/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from 'react';

//@ts-ignore
import data from '@banuba/webar/BanubaSDK.data';
//@ts-ignore
import wasm from '@banuba/webar/BanubaSDK.wasm';
//@ts-ignore
import simd from '@banuba/webar/BanubaSDK.simd.wasm';
//@ts-ignore
import FaceTracker from '@banuba/webar/face_tracker.zip';
//@ts-ignore
import Lips from '@banuba/webar/lips.zip';
//@ts-ignore
import Makeup from './assets/effects/Makeup.zip';

import { Webcam, Player, Module, Effect, Dom } from '@banuba/webar';
import { Box, Button, FlexLayout, Icon, Image, Link, Slider, Text, useScreenType } from '../ui';
import Logo from '../ui/assets/images/Logo.png';
import { useLocation } from 'react-router-dom';
import { AnalyticsEventNames, trackAnalyticsEvent } from '../utils';
import { TrackingBanner } from '../pages-components/TrackingBanner';

const effect = new Effect(Makeup);

const SdkComponentBotox = () => {
  const { pathname } = useLocation();
  const { isMobile } = useScreenType();
  const isMobileConst = isMobile();

  const [sizeScale, setSizeScale] = useState(0);
  const [thicknessScale, setThicknessScale] = useState(0);
  const [shapeScale, setShapeScale] = useState(0);

  useEffect(() => {
    const pathnameElements = pathname.split('-');
    trackAnalyticsEvent(AnalyticsEventNames.SDK_Loaded, {
      client: pathnameElements[0].replace('/', ''),
      clinicName: pathnameElements[pathnameElements.length - 1],
    });
  }, []);

  const playerRef = useRef<Player>();
  const [isPlayerLoading, setIsPlayerLoading] = useState<boolean>(true);

  const firstFrameReceivedListener = () => {
    setIsPlayerLoading(false);
    playerRef.current?.removeEventListener('framereceived', firstFrameReceivedListener);
  };

  useEffect(() => {
    async function initSdk() {
      const newPlayer = await Player.create({
        clientToken: process.env.REACT_APP_BANUBA_TOKEN as string,
        locateFile: {
          'BanubaSDK.data': data,
          'BanubaSDK.wasm': wasm,
          'BanubaSDK.simd.wasm': simd,
        },
      });
      playerRef.current = newPlayer;

      await newPlayer.addModule(new Module(Lips), new Module(FaceTracker));

      newPlayer.use(new Webcam());
      await newPlayer.applyEffect(effect);
      Dom.render(newPlayer, '#webar-app');
      newPlayer.addEventListener('framereceived', firstFrameReceivedListener);
    }

    initSdk();

    return (): void => {
      Dom.unmount('#webar-app');
    };
  }, []);

  useEffect(() => {
    effect.evalJs(`FaceMorph.lips({size: ${sizeScale}})`);
  }, [sizeScale]);

  useEffect(() => {
    effect.evalJs(`FaceMorph.lips({thickness: ${thicknessScale}})`);
  }, [thicknessScale]);

  useEffect(() => {
    effect.evalJs(`FaceMorph.lips({shape: ${-1 * shapeScale}})`);
  }, [shapeScale]);

  return (
    <>
      <FlexLayout
        space={32}
        flexDirection={'column'}
        sx={{ mx: 'auto', maxWidth: '1200px', py: '16px', px: '10px' }}
      >
        <FlexLayout justifyContent={'space-between'}>
          <Image src={Logo} sx={{ objectFit: 'contain' }} />
        </FlexLayout>
        {isPlayerLoading && (
          <FlexLayout justifyContent='center'>
            <Icon icon='loading' size='xxl' />
          </FlexLayout>
        )}
        <div
          id='webar-app'
          style={{ position: 'relative', overflow: 'hidden', borderRadius: '24px' }}
        />
        <FlexLayout
          flexDirection='column'
          space={16}
          sx={{ '&>div': { width: '100%' } }}
          justifyContent='center'
          alignItems={'center'}
        >
          <FlexLayout space={4} alignItems='center'>
            <Text sx={{ fontFamily: 'Euclid Regular', textAlign: 'right', flex: 1 }}>Size</Text>
            <Slider
              max={1}
              min={-1}
              onChangeScale={(value) => setSizeScale(value)}
              scale={sizeScale}
              showOrigin={true}
              step={0.001}
              sx={{ textAlign: 'center', width: '55%' }}
            />
            <Text sx={{ fontFamily: 'Euclid Regular', textAlign: 'left', flex: 1 }}>
              {sizeScale.toFixed(2)}
            </Text>
          </FlexLayout>
          <FlexLayout space={4} alignItems='center'>
            <Text sx={{ fontFamily: 'Euclid Regular', textAlign: 'right', flex: 1 }}>
              Thickness
            </Text>
            <Slider
              max={1}
              min={-1}
              onChangeScale={(value) => setThicknessScale(value)}
              scale={thicknessScale}
              showOrigin={true}
              step={0.001}
              sx={{ textAlign: 'center', width: '55%' }}
            />
            <Text sx={{ fontFamily: 'Euclid Regular', textAlign: 'left', flex: 1 }}>
              {thicknessScale.toFixed(2)}
            </Text>
          </FlexLayout>
          <FlexLayout space={4} alignItems='center'>
            <Text sx={{ fontFamily: 'Euclid Regular', textAlign: 'right', flex: 1 }}>Shape</Text>
            <Slider
              max={1}
              min={-1}
              onChangeScale={(value) => setShapeScale(value)}
              scale={shapeScale}
              step={0.001}
              showOrigin={true}
              sx={{ textAlign: 'center', width: '55%' }}
            />
            <Text sx={{ fontFamily: 'Euclid Regular', textAlign: 'left', flex: 1 }}>
              {shapeScale.toFixed(2)}
            </Text>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        sx={{ fontFamily: 'Euclid Regular', px: '10px', maxWidth: '1200px', mx: 'auto' }}
        flexDirection={'column'}
        space={3}
      >
        <Text as='h1'>Perfecting pouts without the pinch:</Text>
        <Box as='ol'>
          <li>Enable camera access on your device</li>
          <li>Position yourself within the frame</li>
          <li>Smile or maintain a neutral expression</li>
          <li>Use the slider bar to increase volume of lips to your preference</li>
        </Box>
        <Text as='p'>Like what you see? Reach out to modernize your practice with YSS.</Text>
        <Link href='https://www.yoursocialsmile.com/partner'>
          <Button size='standard' text={'Partner with us'} variant='irisBlue' />
        </Link>
      </FlexLayout>
      <TrackingBanner />
    </>
  );
};

export default SdkComponentBotox;
